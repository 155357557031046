import { StorageHelper } from './../helpers/Storage';

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "api/models";
import { AppThunk, RootState } from "./store";

const initialState = {
  logged_in: false,
  user: new User(),
};

export const loginReducer = createSlice({
  name: "loginReducer",
  initialState,
  reducers: {

    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.logged_in = action.payload;
    },

    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      StorageHelper.setItem('user', JSON.stringify(action.payload));
    },

    setLogout: (state, action: PayloadAction<boolean>) => {
      state = initialState;
      StorageHelper.clear();
    },
  },
});

export const {
  setLoggedIn,
  setUser,
  setLogout,
} = loginReducer.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const attemptLogin = (
  username: string,
  password: string,
  cb: VoidFunction
): AppThunk => (dispatch) => {
  setTimeout(() => {
    dispatch(setLoggedIn(true));
    cb();
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLoggedIn = (state: RootState) => state.login.logged_in;
export const selectUserLoggedIn = (state: RootState): User => state.login.user as User;

export default loginReducer.reducer;
