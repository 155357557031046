import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { uniqueId } from "lodash";
import React from "react";

interface IInputPasswordProps extends OutlinedInputProps {
    classFormControl?: string;
    label: string;
    validate?: boolean;
    matchValue?: string;
    onSetError?: (hasError: boolean, message: string) => void;
}

/*
RegEx	            Description
^	                The password string will start this way
(?=.*[a-z])	        The string must contain at least 1 lowercase alphabetical character
(?=.*[A-Z])	        The string must contain at least 1 uppercase alphabetical character
(?=.*[0-9])	        The string must contain at least 1 numeric character
(?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
(?=.{8,})	        The string must be eight characters or longer
*/
const InputPassword: React.FC<IInputPasswordProps> = (props) => {

    const { id, label, labelWidth, classFormControl, value, validate, matchValue, onSetError, ...otherProps } = props;
    const newId = id ? id : uniqueId('input-password-adornment');
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [messageError, setMessageError] = React.useState<string>("");

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validatePassword = (password: string) => {
        let result = true;
        let message = '';
        if (validate) {
            const pattern = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
            setMessageError('');
            if (matchValue) {
                if ((password.length > 0) && (matchValue.length > 0) && (password !== matchValue)) {
                    message = 'As senhas informdas são diferentes.';
                }
            } else if (validate) {
                if ((password.length > 0) && (!password.match(pattern))) {
                    message = 'A senha informada não atende os requisitos minímos de segurança';
                }
            }
        }
        setMessageError(message);
        onSetError && onSetError(result, message);
        return result;
    }


    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleonKeyUpPassword = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        const text = String(event.currentTarget.value);
        validatePassword(text);
    };

    const handleOnBlurPassword = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        const text = String(event.currentTarget.value);
        validatePassword(text);
    };


    return (
        <FormControl
            variant="outlined"
            fullWidth
            className={classFormControl}
        >
            <InputLabel htmlFor={newId}>
                <Typography variant="subtitle2">
                    {label}
                </Typography>
            </InputLabel>
            <OutlinedInput
                id={newId}
                {...otherProps}
                type={showPassword ? 'text' : 'password'}
                value={value}
                error={messageError.length > 0}
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={(label.length * 8) + 6}
                onKeyUp={handleonKeyUpPassword}
                onBlur={handleOnBlurPassword}
            />
            {
                (messageError.length > 0) && (<FormHelperText error={messageError.length > 0}>{messageError}</FormHelperText>)
            }
        </FormControl>
    );
}
export default InputPassword;