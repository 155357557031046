import { Loading } from 'reducers/pageLoadReducer';

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlertMessage } from "components/AlertMessage";
import { RootState } from "./store";


const initialState = {
  options: {
    show: false,
    message: "",
    severity: "info",
  }
};

export const alertReducer = createSlice({
  name: "alertReducer",
  initialState,
  reducers: {
    setAlertMessage: (state, action: PayloadAction<IAlertMessage>) => {
      state.options = action.payload;
    },
  },
});

export const { setAlertMessage } = alertReducer.actions;

export const Mensagem = {
  info: (dispatch: any, mensagem: string = "") => { dispatch(setAlertMessage({ show: true, message: mensagem, severity: "info" })); },
  error: (dispatch: any, mensagem: string = "", closeLoad: boolean = true) => {
    if (closeLoad) {
      Loading.close(dispatch);
    }
    dispatch(
      setAlertMessage({ show: true, message: mensagem, severity: "error" })
    );
  },
  warning: (dispatch: any, mensagem: string = "") => { dispatch({ show: true, message: mensagem, severity: "warning" }); },
  success: (dispatch: any, mensagem: string = "") => { dispatch({ show: true, message: mensagem, severity: "success" }); }
};

export const selectAlertMessage = (state: RootState): IAlertMessage => state.alert.options as IAlertMessage;

export default alertReducer.reducer;