import React from 'react'
import LayoutContainer from "./../../layout/LayoutContainer";
import Capa from "assets/img/capa.jpg";
interface ILandingPageProps {

}

const LandingPage: React.FC<ILandingPageProps> = (props) => {
  return (
    <LayoutContainer>
      <img src={Capa} alt={"capa"} style={{ width: "100%" }} />
    </LayoutContainer>
  )
}

export default LandingPage;