import { Box, BoxProps, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { themeStyles } from "layout/theme";
import React, { Fragment } from "react";

export interface IPanelProps extends BoxProps {
    title?: string;
    icon?: React.ReactChild;
    divider?: boolean;
    titleAlign?: "center" | "left" | "right" | "justify";
    children?: React.ReactChild;
    renderTitle?: React.ReactNode;
    renderAction?: React.ReactNode;
    classTitle?: string;
}

const Panel: React.FC<IPanelProps> = (props) => {
    const {
        children,
        title,
        icon,
        divider,
        titleAlign,
        className,
        classTitle,
        renderTitle,
        renderAction,
        ...otherProps
    } = props;

    const theme = themeStyles();
    const panelCompClass = clsx(theme.panelComp, className);

    return (
        <Box className={panelCompClass} {...otherProps}>
            {!renderTitle && (title || icon) && (
                <Fragment>
                    <Grid container>
                        {title && (
                            <Grid item xs sm lg style={{ textAlign: titleAlign }}>
                                <Typography display="block" gutterBottom variant="h6" className={theme.panelTitle}>
                                    {title}
                                </Typography>
                            </Grid>
                        )}
                        {icon && (
                            <Grid item xs={2} sm={2} lg={2}>
                                <Box mt={0.5} display="flex" justifyContent="flex-end">
                                    {icon}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    {divider && <Divider />}
                </Fragment>
            )}
            <div>{renderTitle}</div>
            <div className={theme.panelChildren}>{children}</div>

            {renderAction}
        </Box>
    );
};

Panel.defaultProps = {
    width: "100%",
    divider: false,
    titleAlign: "left",
};

export default Panel;
