import {
    createMuiTheme,
    makeStyles,
    responsiveFontSizes,
    Theme
} from "@material-ui/core/styles";
import "fontsource-open-sans";

// change this object in order to modify general properties of the
// material-UI components, as seen here: https://material-ui.com/customization/theming/

/*
-------------------
|   PIXEL TO REM   |
--------------------
| 10px | 0.625rem  |
| 11px | 0.6875rem |
| 12px | 0.75rem   |
| 13px | 0.8125rem |
| 14px | 0.875rem  |
| 15px | 0.9375rem |
| 16px | 1rem      |
| 17px | 1.0625rem |
| 18px | 1.125rem  |
| 19px | 1.1875rem |
| 20px | 1.25rem   |
| 21px | 1.3125rem |
| 22px | 1.375rem  |
| 23px | 1.4375rem |
| 24px | 1.5rem    |
| 25px | 1.5625rem |
| 26px | 1.625rem  |
| 27px | 1.6875rem |
| 28px | 1.75rem   |
| 29px | 1.8125rem |
| 30px | 1.875rem  |
| 31px | 1.9375rem |
| 32px | 2rem      |
--------------------
*/
const defaulFontFamily = "'Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'";


let theme = createMuiTheme({

    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    WebkitFontSmoothing: 'auto',
                },
                body: {
                    //backgroundColor: "#0066CC"
                }
            },
        },
    },

    palette: {
        primary: {
            light: '#535da',
            main: '#1c2566',
            dark: '#283593',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffb333',
            main: '#b27000',
            dark: '#ffa000',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: defaulFontFamily,
    }
});

theme = responsiveFontSizes(theme);

export default theme;

export const themeStyles = makeStyles((theme: Theme) => {
    return (
        {
            panelComp: {
                backgroundColor: theme.palette.background.paper,
                borderRadius: "5px",
                boxShadow: "0px 0px 5px 2px rgba(221,223,224,0.5)",
                padding: "15px 15px 15px 15px",
                fontFamily: theme.typography.fontFamily,
            },

            panelTitle: {
                fontWeight: "bold",
                fontFamily: theme.typography.fontFamily,
            },

            panelChildren: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
                fontFamily: theme.typography.fontFamily,
            },

            panelBodyComp: {
                fontSize: "10px",
                fontWeight: "normal",
                fontColor: "#464647",
                fontFamily: theme.typography.fontFamily,
            },
        }
    )
});
