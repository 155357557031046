import { Box, FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import HttpClient, { API_USUARIO_PASSWORD_RESET, sleep } from "api/Api";
import IResponseApi from "api/interfaces/IResponseApi";
import { LogoMarca } from "components";
import InputEmail from "components/InputEmail";
import { ValidateHelper } from "helpers/ValidationHelpers";
import WithAlert, { IWithAlertProps } from "hoc/WithAlert";
import WithLoading, { IWithLoadingProps } from "hoc/WithLoading";
import React from "react";
import passwordResetStyle from "./passwordResetStyle";

interface IPasswordResetProps {

}

const PasswordReset: React.FC<IPasswordResetProps> = (props) => {


  const classes = passwordResetStyle();

  const { showLoading, closeLoading } = props as IWithLoadingProps;
  const { alertError, alertInfo } = props as IWithAlertProps;

  const [email, setEmail] = React.useState<string>('');
  const [messageError, setMessageError] = React.useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setMessageError('');
  };

  const handleErrorNotification = (mensagem: string) => {
    closeLoading && closeLoading();
    alertError && alertError(mensagem);
  }

  const handleInfoNotification = (mensagem: string) => {
    closeLoading && closeLoading();
    alertInfo && alertInfo(mensagem);
    sleep(6000).then(() => {
      window.location.href = '/login';
    });
  }

  const handleValidate = (): boolean => {
    let result = false;
    if (email.length <= 0) {
      setMessageError('E-mail não informado.');
    } else if (!ValidateHelper.Email(email)) {
      setMessageError('E-mail informado é inválido.');
    } else {
      setMessageError('');
      result = true;
    }
    return result;
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //event.preventDefault();

    if (handleValidate()) {
      showLoading && showLoading();

      const bodyFormData = new FormData();
      bodyFormData.append('email', email);
      bodyFormData.append('from', 'portaldoaluno.isantoinacio.com.br');
      HttpClient.post(API_USUARIO_PASSWORD_RESET, bodyFormData).then((response) => {

        const resp = (response.data as IResponseApi);
        if (resp.success) {
          handleInfoNotification(resp.message);
        } else {
          handleErrorNotification(resp.message);
        }
      })
        .catch((err: Error) => {
          handleErrorNotification(err.message);
        });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <LogoMarca title="Portal do aluno - Recuperar senha" />

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputEmail label="Email" onChange={handleChange} validate />
        </Box>

        {
          (messageError.length > 0) && (
            <FormHelperText
              error={messageError.length > 0}
            >
              {messageError}
            </FormHelperText>
          )
        }

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Enviar
        </Button>

        <Link href="/login" variant="body2" className={classes.link}>Voltar para página de login?</Link>
      </div>
    </Container>
  );
}

export default WithAlert(
  WithLoading(PasswordReset)
); 