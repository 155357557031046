import { Grid, makeStyles, Theme } from '@material-ui/core';
import HttpClient, { API_ALUNO_LIST } from 'api/Api';
import { IAluno, IBoletim, IResponseApi } from 'api/interfaces';
import Panel from 'components/Panel';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Mensagem } from "reducers/alertReducer";
import { Loading } from "reducers/pageLoadReducer";
import LayoutContainer from "./../../layout/LayoutContainer";
import ListAluno from './ListAluno';
import NotasDetail from './NotasDetail';

interface INotasPageProps {

}

const useStyles = makeStyles((theme: Theme) => ({
    root: {

    },

    panel: {
        minHeight: "40rem",
    },
}));


const NotasPage: React.FC<INotasPageProps> = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [alunos, setAlunos] = useState<IAluno[]>([]);
    const [boletim, setBoletim] = useState<IBoletim | undefined>(undefined);
    const [aluno, setAluno] = useState<IAluno | undefined>(undefined);


    useEffect(() => {
        let effect = true;
        if (effect) {
            (async () => {
                Loading.open(dispatch);
                HttpClient.get(API_ALUNO_LIST + '?all=yes').then((response) => {
                    const resp = (response.data as IResponseApi);
                    if (resp.success) {
                        setAlunos(resp.data as IAluno[]);
                        Loading.close(dispatch);
                    } else {
                        Mensagem.error(dispatch, resp.message);
                    }
                }).catch((err: Error) => {
                    Mensagem.error(dispatch, err.message);
                });
            })();
        }
        return () => {
            effect = false
        }
    }, [dispatch])

    return (
        <LayoutContainer className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                    <Panel title={"Aluno(a)"} className={classes.panel}>
                        <ListAluno alunos={alunos} onSelectAluno={setAluno} onSelectBoletim={setBoletim} />
                    </Panel>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                    <Panel title={"Boletim"} className={classes.panel}>
                        {
                            aluno && boletim && (<NotasDetail aluno={aluno} boletim={boletim} />)
                        }
                    </Panel>
                </Grid>
            </Grid>
        </LayoutContainer>
    )
}

export default NotasPage;