import { Box, FormHelperText, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import HttpClient, { API_USUARIO_LOGIN } from "api/Api";
import { IUser } from "api/interfaces";
import IResponseApi from "api/interfaces/IResponseApi";
import { User } from "api/models";
import { InputPassword, InputUsername, LogoMarca } from "components";
import WithAlert, { IWithAlertProps } from "hoc/WithAlert";
import WithLoading, { IWithLoadingProps } from "hoc/WithLoading";
import WithUserLoginProps, { IWithUserLoginProps } from "hoc/WithUserLogin";
import React from "react";
import { useHistory } from "react-router-dom";
import loginStyle from "./loginStyles";

interface ILoginProps {

}
interface IFormState {
  username: '',
  password: ''
}

const Login: React.FC<ILoginProps> = (props) => {

  const classes = loginStyle();
  let history = useHistory();

  const { showLoading, closeLoading } = props as IWithLoadingProps;
  const { alertError } = props as IWithAlertProps;
  const { doLogin } = props as IWithUserLoginProps;

  const [values, setValues] = React.useState<IFormState>({ username: '', password: '' });
  const [remember, setRemember] = React.useState<boolean>(true);
  const [messageError, setMessageError] = React.useState<string>("");

  const handleChange = (prop: keyof IFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setMessageError('');
  };

  const handleErrorNotification = (mensagem: string) => {
    closeLoading && closeLoading();
    alertError && alertError(mensagem);
  }

  const handleValidate = (): boolean => {
    let result = false;
    if (values.username.length <= 0) {
      setMessageError('Matrícula ou senha não informada.');
    } else if (values.password.length <= 0) {
      setMessageError('Senha não informada.');
    } else {
      setMessageError('');
      result = true;
    }
    return result;
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (handleValidate()) {
      showLoading && showLoading();

      const bodyFormData = new FormData();
      bodyFormData.append('username', values.username);
      bodyFormData.append('password', values.password);

      HttpClient.post(API_USUARIO_LOGIN, bodyFormData).then((response) => {

        const resp = (response.data as IResponseApi);
        if (resp.success) {
          const user = new User((response.data as IResponseApi).data as IUser);
          doLogin(user, () => {
            closeLoading && closeLoading();
            history.replace("/dashboard");
          });
        } else {
          handleErrorNotification(resp.message);
        }
      }).catch((err: Error) => {
        handleErrorNotification(err.message);
      });
    }
  }

  // const callback = function () {
  //   console.log('callback Done!!!!');
  // };

  // // specifying verify callback function
  // const verifyCallback = function (response: any) {
  //   console.log('verifyCallback', response);
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <LogoMarca />

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputUsername
            label="Matrícula ou CPF"
            validate
            onChange={handleChange('username')}
            required />
        </Box>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputPassword
            label="Senha"
            value={values.password}
            onChange={handleChange('password')}
            required
          />
        </Box>

        <Box style={{ width: "100%", textAlign: "left" }}>
          {
            (messageError.length > 0) && (<FormHelperText error={messageError.length > 0}>{messageError}</FormHelperText>)
          }
        </Box>

        {/* <Box style={{ width: "100%", textAlign: "left" }}>
          <Recaptcha
            sitekey="6Ldjo_sZAAAAAFVzwZ6Lbvqx2gtIprhJc0G5yet1"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
          />
        </Box> */}
        <Box mt={1} style={{ width: "100%", textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={remember}
                onChange={(e) => setRemember(e.target.checked)}
              />
            }
            label="Manter conectado"
          />
        </Box>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Entrar
        </Button>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item justify="flex-start">
            <Link href="/reset-password" variant="body2">Esqueceu a senha?</Link>
          </Grid>
          <Grid item justify="flex-end">
            <Link href="/sign-up" variant="body2">Ainda não é cadastrado?</Link>
          </Grid>
        </Grid>

      </div>
    </Container >
  );
}

export default WithAlert(
  WithLoading(
    WithUserLoginProps(Login)
  )
);