import { Container, ContainerProps } from "@material-ui/core";
import React from "react";
import Copyright from "./Copyright";
interface IContainerProps extends ContainerProps {

}

const LayoutContainer: React.FC<IContainerProps> = (props) => {
  const {
    children,
    ...otherProps
  } = props;

  return (
    <div>
      <Container {...otherProps} style={{ marginBottom: "3rem", marginTop: "3rem" }}>
        {children}
      </Container>
      <Copyright />
    </div>
  );
};

export default LayoutContainer;
