import { Box, FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import HttpClient, { API_USUARIO_CHANGE_PASSWORD_TOKEN, sleep } from "api/Api";
import { IResponseApi } from "api/interfaces";
import { LogoMarca } from "components";
import InputPassword from "components/InputPassword";
import WithAlert, { IWithAlertProps } from "hoc/WithAlert";
import WithLoading, { IWithLoadingProps } from "hoc/WithLoading";
import React from "react";
import changePasswordtStyle from "./changePasswordStyle";

interface IChangePasswordProps {

}

interface IFormState {
  password: '',
  confirmPassword: ''
}

const ChangePassword: React.FC<IChangePasswordProps> = (props) => {

  const classes = changePasswordtStyle();
  const { showLoading, closeLoading } = props as IWithLoadingProps;
  const { alertError, alertInfo } = props as IWithAlertProps;

  const [values, setValues] = React.useState<IFormState>({ password: '', confirmPassword: '' });
  const [messageError, setMessageError] = React.useState<string>("");

  const handleChange = (prop: keyof IFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setMessageError('');
  };

  const handleErrorNotification = (mensagem: string) => {
    closeLoading && closeLoading();
    alertError && alertError(mensagem);
  }

  const handleInfoNotification = (mensagem: string) => {
    alertInfo && alertInfo(mensagem);
    sleep(6000).then(() => {
      window.location.href = '/login';
    });
  }

  const handleValidate = (): boolean => {
    let result = false;
    if (values.password.length <= 0) {
      setMessageError('Senha não informada.');
    } else if (values.confirmPassword.length <= 0) {
      setMessageError('Confirmação da senha não informada.');
    } else if ((values.password.length > 0) && (values.confirmPassword.length > 0) && (values.password !== values.confirmPassword)) {
      setMessageError('As senhas são diferentes.');
    } else {
      setMessageError('');
      result = true;
    }
    return result;
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //event.preventDefault();

    if (handleValidate()) {
      showLoading && showLoading();

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      const bodyFormData = new FormData();

      bodyFormData.append('password', values.password);
      bodyFormData.append('password_repeat', values.confirmPassword);
      bodyFormData.append('token', token ? token : '');
      HttpClient.post(API_USUARIO_CHANGE_PASSWORD_TOKEN, bodyFormData).then((response) => {

        const resp = (response.data as IResponseApi);
        if (resp.success) {
          handleInfoNotification(resp.message);
        } else {
          handleErrorNotification(resp.message);
        }
      })
        .catch((err: Error) => {
          handleErrorNotification(err.message);
        });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

        <LogoMarca title="Portal do aluno - alterar senha" />

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputPassword
            label="Senha"
            value={values.password}
            onChange={handleChange('password')}
            required
            validate
          />
        </Box>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputPassword
            label="Confirmação da senha"
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            required
            validate
            matchValue={values.password}
          />
        </Box>
        {
          (messageError.length > 0) && (<FormHelperText error={messageError.length > 0}>{messageError}</FormHelperText>)
        }

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </div>
    </Container>
  );
}

export default WithAlert(
  WithLoading(ChangePassword)
);