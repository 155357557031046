import { Box, FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import HttpClient, { API_USUARIO_SIGNUP } from "api/Api";
import { IUser } from "api/interfaces";
import IResponseApi from "api/interfaces/IResponseApi";
import { User } from "api/models";
import { InputEmail, InputPassword, InputUsername, LogoMarca } from "components";
import WithAlert, { IWithAlertProps } from "hoc/WithAlert";
import WithLoading, { IWithLoadingProps } from "hoc/WithLoading";
import WithUserLoginProps, { IWithUserLoginProps } from "hoc/WithUserLogin";
import React from "react";
import { useHistory } from "react-router-dom";
import signUpStyles from "./signUpStyles";
interface ISignUpProps {

}

interface IFormState {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
}

const SignUp: React.FC<ISignUpProps> = (props) => {

  const classes = signUpStyles();
  let history = useHistory();

  const { showLoading, closeLoading } = props as IWithLoadingProps;
  const { alertError } = props as IWithAlertProps;
  const { doLogin } = props as IWithUserLoginProps;

  const [values, setValues] = React.useState<IFormState>({ username: '', password: '', confirmPassword: '', email: '' });
  const [messageError, setMessageError] = React.useState<string>("");

  const handleChange = (prop: keyof IFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setMessageError('');
  };

  const handleErrorNotification = (mensagem: string) => {
    closeLoading && closeLoading();
    alertError && alertError(mensagem);
  }

  const handleValidate = (): boolean => {
    let result = false;
    if (values.username.length <= 0) {
      setMessageError('Matrícula ou senha não informada.');
    } else if (values.email.length <= 0) {
      setMessageError('Email não informado.');
    } else if (values.password.length <= 0) {
      setMessageError('Senha não informada.');
    } else if (values.password !== values.confirmPassword) {
      setMessageError('Senha e confirmação da senha são diferentes.');
    } else {
      setMessageError('');
      result = true;
    }
    return result;
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (handleValidate()) {
      showLoading && showLoading();

      const bodyFormData = new FormData();
      bodyFormData.append('username', values.username);
      bodyFormData.append('password', values.password);
      bodyFormData.append('repeatPassword', values.confirmPassword);
      bodyFormData.append('email', values.email);

      HttpClient.post(API_USUARIO_SIGNUP, bodyFormData).then((response) => {

        const resp = (response.data as IResponseApi);
        if (resp.success) {
          const user = new User((response.data as IResponseApi).data as IUser);
          doLogin(user, () => {
            closeLoading && closeLoading();
            history.replace("/dashboard");
          });
        } else {
          handleErrorNotification(resp.message);
        }
      }).catch((err: Error) => {
        handleErrorNotification(err.message);
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <LogoMarca title="Portal do aluno - Cadastro" />

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputUsername
            label="Matrícula ou CPF"
            validate
            onChange={handleChange('username')}
            required />
        </Box>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputEmail
            label="Email"
            value={values.email}
            onChange={handleChange('email')}
            validate
          />
        </Box>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputPassword
            label="Senha"
            value={values.password}
            onChange={handleChange('password')}
            required
            validate
          />
        </Box>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputPassword
            label="Confirmar senha"
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            required
            matchValue={values.password}
            validate
          />
        </Box>

        <Box style={{ width: "100%", textAlign: "left" }}>
          {
            (messageError.length > 0) && (<FormHelperText error={messageError.length > 0}>{messageError}</FormHelperText>)
          }
        </Box>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Registrar
        </Button>

        <Link href="/login" variant="body2">Voltar para o login.</Link>
      </div>
    </Container >
  );
}

export default WithAlert(
  WithLoading(
    WithUserLoginProps(SignUp)
  )
); 