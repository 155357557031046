import { Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from "@material-ui/icons/Menu";
import navigationStyles from "assets/css/navigationStyles";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "reducers/loginReducer";
import MiniLogo from "./../assets/img/logo2.png";
import NavigationRouters from "./NavigationRouters";
import SideBar from "./Sidebar";

interface INavigationProps {

}

const Navigation: React.FC<INavigationProps> = (props) => {

  const classes = navigationStyles();
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Portal do Aluno
          </Typography>

          <IconButton color="inherit"
            onClick={() => dispatch(setLoggedIn(false))}
          >
            <Tooltip title="Sair" aria-label="Sair">
              <Badge color="secondary">
                <ExitToAppIcon />
              </Badge>
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>


      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={MiniLogo} alt="Logo" style={{ width: "80%" }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />

        <List>
          <SideBar />
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <NavigationRouters />
      </main>
    </div>
  );
}

export default Navigation;