import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPageLoader } from 'reducers/pageLoadReducer';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 9999,
            color: '#fff',
        },
    }),
);
export interface IPageLoader {
    open: boolean;
    message: string;
}


interface IPageLoaderProps {

}

const PageLoader: React.FC<IPageLoaderProps> = (props) => {
    const classes = useStyles();
    const pageLoadRdx = useSelector(selectPageLoader);
    const useRef = React.createRef();
    return (
        <div>
            {
                pageLoadRdx.open && (
                    <Backdrop ref={useRef} className={classes.backdrop} open={pageLoadRdx.open} >
                        {pageLoadRdx.message}
                        <CircularProgress color="primary" />
                    </Backdrop >
                )
            }
        </div>
    );
}

export default PageLoader;