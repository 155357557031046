import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMessage, selectAlertMessage } from 'reducers/alertReducer';

function CustomAlert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface IAlertMessage {
    message: string;
    show: boolean;
    severity: "error" | "warning" | "info" | "success";
}

interface IAlertMessageProps {
}

const AlertMessage: React.FC<IAlertMessageProps> = (props) => {

    const dispatch = useDispatch();
    const alertMessageRdx = useSelector(selectAlertMessage);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setAlertMessage({
            message: "",
            show: false,
            severity: "info"
        }));
    };

    return (
        <Snackbar open={alertMessageRdx.show} autoHideDuration={6000} onClose={handleClose}>
            <CustomAlert onClose={handleClose} severity={alertMessageRdx.severity}>
                {alertMessageRdx.message}
            </CustomAlert>
        </Snackbar>
    );
}

export default AlertMessage;