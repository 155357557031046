import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps, Typography } from "@material-ui/core";
import { uniqueId } from "lodash";
import React from "react";
import PersonIcon from '@material-ui/icons/Person';
import { ValidateHelper } from "helpers/ValidationHelpers";

interface IInputUsernameProps extends OutlinedInputProps {
    classFormControl?: string;
    label: string;
    validate?: boolean;
    onSetError?: (hasError: boolean, message: string) => void;
}

const InputUsername: React.FC<IInputUsernameProps> = (props) => {

    const { id, label, classFormControl, error, children, validate, onSetError, ...otherProps } = props;
    const newId = id ? id : uniqueId('input-adornment');

    const [messageError, setMessageError] = React.useState<string>("");

    const validateUsername = (username: string) => {
        let result = true;
        let message = '';
        if (validate) {
            //const regexCPF = /^\d{3}\d{3}\d{3}\d{2}$/;
            const regexMatricula = /^2\d{3}\d{5}$/;
            if (username.length <= 0) {
                message = '';
                result = true;
            } else {
                if (username.length === 9) {
                    if (!username.match(regexMatricula)) {
                        message = "Informe uma matrícula válida.";
                        result = false;
                    }
                } else if (username.length === 11) {
                    if (!ValidateHelper.CPF(username)) {
                        message = "Informe um CPF válido.";
                        result = false;
                    }
                } else {
                    message = "Informe uma matrícula ou CPF válido.";
                    result = false;
                }
            }
        }
        setMessageError(message);
        onSetError && onSetError(result, message);
        return result;
    }

    const handleOnKeyUpPassword = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '');
        const text = String(event.currentTarget.value);
        validateUsername(text);
    };

    const handleOnBlurPassword = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '');
        const text = String(event.currentTarget.value);
        validateUsername(text);
    };

    return (
        <FormControl
            variant="outlined"
            fullWidth
            className={classFormControl}
        >
            <InputLabel htmlFor={newId}>
                <Typography variant="subtitle2">
                    {label}
                </Typography>
            </InputLabel>
            <OutlinedInput
                id={newId}
                {...otherProps}
                error={messageError.length > 0}
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                        <PersonIcon />
                    </InputAdornment>
                }
                inputProps={{ maxLength: 11 }}
                labelWidth={(label.length * 8) + 6}
                onKeyUp={handleOnKeyUpPassword}
                onBlur={handleOnBlurPassword}
            />
            {
                (messageError.length > 0) && (<FormHelperText error={messageError.length > 0}>{messageError}</FormHelperText>)
            }
        </FormControl >
    );
}
export default InputUsername;