
import { IUser } from 'api/interfaces';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosStatic } from "axios";
import { setLogout } from 'reducers/loginReducer';
import { StorageHelper } from './../helpers/Storage';
import { store } from './../reducers/store';

export const API_USUARIO_LOGIN = "usuario/login";
export const API_USUARIO_PASSWORD_RESET = "usuario/forgot";
export const API_USUARIO_CHANGE_PASSWORD_TOKEN = "usuario/reset-password";
export const API_USUARIO_SIGNUP = "usuario/signup";
export const API_USUARIO_CHANGE_PASSWORD = "usuario/update-password";


export const API_ALUNO_LIST = 'aluno/list';
export const API_ALUNO_DETAIL = 'aluno/detail';


// async function refreshToken(error: Error) {
//     return new Promise((resolve, reject) => {
//         try {
//             const refresh_token = localStorage.getItem("refresh_token");
//             const header = {
//                 "Content-Type": "application/json",
//                 Authorization: process.env.AUTHORIZATION,
//             };
//             const parameters = {
//                 method: "POST",
//                 headers: header,
//             };
//             const body = {
//                 grant_type: "refresh_token",
//                 refresh_token,
//             };
//             axios
//                 .post(
//                     process.env.API_URL + "/refreshtoken",
//                     body,
//                     parameters
//                 )
//                 .then(async (res) => {
//                     localStorage.setItem("access_token", res.data.access_token);
//                     localStorage.setItem("refresh_token", res.data.refresh_token);
//                     // Fazer algo caso seja feito o refresh token
//                     return resolve(res);
//                 })
//                 .catch((err) => {
//                     // Fazer algo caso não seja feito o refresh token
//                     return reject(error);
//                 });
//         } catch (err) {
//             return reject(err);
//         }
//     });
// };


axios.defaults.baseURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "";

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
//axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data; boundary=something";

axios.interceptors.request.use(
    (config: any) => {
        // Do something before request is sent
        let token = "";
        if (StorageHelper.has('login')) {
            let user: any = StorageHelper.getItem('user');
            if (user) {
                user = JSON.parse(user) as IUser;
                token = user.accessToken;
            }
        }
        const configClone = Object.assign({}, config);
        configClone.headers.Authorization = `Bearer ${token}`;
        //console.log("axios.interceptors.request", configClone);
        return configClone;
    },
    async (error: Error) => {
        //console.log("axios.interceptors.request error", error);
        // if (error != null && error.status === 401) {
        //     store.dispatch(setLogout(true));
        //     window.location.href = '/login'
        // }

        // let access_token = "";
        // let user: any = StorageHelper.getItem('user');
        // if (user) {
        //     user = JSON.parse(user) as IUser;
        //     access_token = user.accessToken;
        // }

        // if (error.response.status === 401 && access_token) {
        //     const response = await refreshToken(error);
        //     return response;
        // }
        //console.log("axios.interceptors.request error", error);
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    (response: any) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        //console.log("axios.interceptors.response", response);
        return response;
    },
    (error: any) => {
        if (error.response) {
            //console.log("axios.interceptors.response.use error.response.data", error.response.data);
            //console.log("axios.interceptors.response.use error.response.status", error.response.status);
            //console.log("axios.interceptors.response.use error.response.headers", error.response.headers);

            if (error && Number(error.response.status) === 401) {
                store.dispatch(setLogout(true));
                window.location.href = '/login'
            }
        } else if (error.request) {
            //console.log("axios.interceptors.response.use error.request", error.request);
        } else {
            //console.log('axios.interceptors.response.use error.message', error.message);
        }
        //console.log('axios.interceptors.response.use error.config', error.config);
        return Promise.reject(error);
    }
);

export function sleep(delay = 1000): Promise<any> {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};

const HttpClient = {
    getAxios: (): AxiosStatic => {
        return axios;
    },

    post: (url: string = "", params: any): Promise<AxiosResponse<any>> => {
        return axios.post(url, params);
    },

    delete: (url: string): Promise<AxiosResponse<any>> => {
        return axios.delete(url);
    },

    put: (url: string, params?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
        return axios.put(url, params, config);
    },

    get: (url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<any>> => {
        return axios.get(url, config);
    },
};

export default HttpClient;
