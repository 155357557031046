import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPageLoader } from './../components/PageLoader';
import { RootState } from "./store";

const initialState = {
  options: {
    open: false,
    message: "",
  }
};

export const pageLoadReducer = createSlice({
  name: "pageLoadReducer",
  initialState,
  reducers: {
    setPageLoader: (state, action: PayloadAction<IPageLoader>) => {
      state.options = action.payload;
    },
  },
});

export const {
  setPageLoader,
} = pageLoadReducer.actions;

export const selectPageLoader = (state: RootState): IPageLoader => state.pageLoad.options as IPageLoader;


export const Loading = {
  open: (dispatch: any, mensagem: string = "") => { dispatch(setPageLoader({ open: true, message: mensagem })); },
  close: (dispatch: any) => { dispatch(setPageLoader({ open: false, message: "" })); },
};


export default pageLoadReducer.reducer;