import React from 'react';
import { useDispatch } from 'react-redux';
import { Mensagem } from 'reducers/alertReducer';

// First we need to add a type to let us extend the incoming component.
export interface IWithAlertProps {
    alertInfo: (message: string) => void;
    alertWarning: (message: string) => void;
    alertError: (message: string) => void;
    alertSuccess: (message: string) => void;
};

export default function WithAlert<P>(WrappedComponent: React.ComponentType<P & IWithAlertProps>) {


    const ComponentWithAlert = (props: P) => {
        const dispatch = useDispatch();

        const alertInfo = (message: string) => {
            Mensagem.info(dispatch, message);
        };

        const alertError = (message: string) => {
            Mensagem.error(dispatch, message);
        };

        const alertWarning = (message: string) => {
            Mensagem.warning(dispatch, message);
        };

        const alertSuccess = (message: string) => {
            Mensagem.success(dispatch, message);
        };

        return <WrappedComponent
            {...props}
            alertInfo={alertInfo}
            alertError={alertError}
            alertWarning={alertWarning}
            alertSuccess={alertSuccess}
        />;
    };
    return ComponentWithAlert;
}