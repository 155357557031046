import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps, Typography } from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import { uniqueId } from "lodash";
import React from "react";


interface IInputEmailProps extends OutlinedInputProps {
    classFormControl?: string;
    label: string;
    validate?: boolean;
    onSetError?: (hasError: boolean, message: string) => void;
}

const InputEmail: React.FC<IInputEmailProps> = (props) => {

    const { id, label, labelWidth, classFormControl, validate, onSetError, ...otherProps } = props;
    const newId = id ? id : uniqueId('input-email-adornment');
    const [messageError, setMessageError] = React.useState<string>("");

    const validateEmail = (email: string) => {
        let result = true;
        let message = '';
        if (validate) {
            const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (email.length <= 0 || email.match(regexEmail)) {
                message = '';
                result = true;
            } else {
                message = "Informe um e-mail válido";
                result = false;
            }
        }
        setMessageError(message);
        onSetError && onSetError(result, message);
        return result;
    }

    const handleOnKeyUpPassword = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        const text = String(event.currentTarget.value);
        validateEmail(text);
    };

    const handleOnBlurPassword = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        event.preventDefault();
        const text = String(event.currentTarget.value);
        validateEmail(text);
    };

    return (
        <FormControl
            variant="outlined"
            fullWidth
            className={classFormControl}
        >
            <InputLabel htmlFor={newId}>
                <Typography variant="subtitle2">
                    {label}
                </Typography>
            </InputLabel>
            <OutlinedInput
                id={newId}
                {...otherProps}
                type="text"
                error={messageError.length > 0}
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                        <Mail />
                    </InputAdornment>
                }
                labelWidth={(label.length * 8) + 6}
                onKeyUp={handleOnKeyUpPassword}
                onBlur={handleOnBlurPassword}
            />
            {
                (messageError.length > 0) && (<FormHelperText error={messageError.length > 0}>{messageError}</FormHelperText>)
            }
        </FormControl>
    );
}
export default InputEmail;