import Panel from 'components/Panel';
import React from 'react';
import LayoutContainer from "./../../layout/LayoutContainer";
import UpdatePassword from './UpdatePassword';

interface IPerfilPageProps {

}

const PerfilPage: React.FC<IPerfilPageProps> = (props) => {
    return (
        <LayoutContainer>
            <Panel title={"Alterar senha"}>
                <UpdatePassword />
            </Panel>
        </LayoutContainer>
    )
}

export default PerfilPage;