import { List, makeStyles, Theme, Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppsIcon from '@material-ui/icons/Apps';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import { Link } from "react-router-dom";

interface ISideBarProps {
}


const useStyles = makeStyles((theme: Theme) => ({
    list: {
        padding: 0,
    },
    listItem: {
        paddingTop: "16px",
        paddingBottom: "16px",
        textDecoration: "none",
        "& span": {
            color: theme.palette.primary.main,
        },
    },
    listIcons: {
        paddingLeft: "5px",
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        "& svg": {
            fontSize: "27px",
        },
        color: theme.palette.primary.main,
    },
    listText: {
        textDecoration: "none",
        fontFamily: theme.typography.fontFamily,
        textTransform: "uppercase",
        "& > :first-child": {
            fontWeight: "bolder",
        },
    },
}));


const SideBar: React.FC<ISideBarProps> = (props) => {
    const classes = useStyles();
    return (
        <List className={classes.list}>
            <Link to="/" className={classes.listItem}>
                <Tooltip title="Home" aria-label="Home">
                    <ListItem button>
                        <ListItemIcon className={classes.listIcons}>
                            <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" className={classes.listText} />
                    </ListItem>
                </Tooltip>
            </Link>
            <Link to="/notas" className={classes.listItem}>
                <Tooltip title="Notas" aria-label="Notas">
                    <ListItem button>
                        <ListItemIcon className={classes.listIcons}>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Notas" className={classes.listText} />
                    </ListItem>
                </Tooltip>
            </Link>
            <Link to="/perfil" className={classes.listItem}>
                <Tooltip title="Perfil" aria-label="Perfil">
                    <ListItem button>
                        <ListItemIcon className={classes.listIcons}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Perfil" className={classes.listText} />
                    </ListItem>
                </Tooltip>
            </Link>
        </List>
    )
}

export default SideBar;