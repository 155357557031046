import { IUser } from 'api/interfaces';
import { User } from 'api/models';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserLoggedIn, setLoggedIn, setLogout, setUser } from 'reducers/loginReducer';

// First we need to add a type to let us extend the incoming component.
export interface IWithUserLoginProps {
    getUser: () => IUser;
    doLogin: (user: IUser, cb?: VoidFunction) => void;
    doLogout: () => void;
};

export default function WithUserLoginProps<P>(WrappedComponent: React.ComponentType<P & IWithUserLoginProps>) {

    const ComponentWithLoading = (props: P) => {
        const dispatch = useDispatch();

        const userLogged = useSelector(selectUserLoggedIn)
        const doLogin = (user: IUser, cb?: VoidFunction) => {
            dispatch(setUser(new User(user)));
            dispatch(setLoggedIn(true));
            cb && cb();
        };

        const doLogout = () => dispatch(setLogout(true));

        const getUser = (): IUser => userLogged as IUser;

        return <WrappedComponent {...props} doLogin={doLogin} doLogout={doLogout} getUser={getUser} />;
    };
    return ComponentWithLoading;
}