import React from 'react';
import { useDispatch } from 'react-redux';
import { Loading } from 'reducers/pageLoadReducer';

// First we need to add a type to let us extend the incoming component.
export interface IWithLoadingProps {
    showLoading: (message?: string) => void;
    closeLoading: () => void;
};

export default function WithLoading<P>(WrappedComponent: React.ComponentType<P & IWithLoadingProps>) {

    const ComponentWithLoading = (props: P) => {
        const dispatch = useDispatch();
        const showLoading = (message?: string) => {
            Loading.open(dispatch, message ? message : "");
        };

        const closeLoading = () => {
            Loading.close(dispatch);
        };
        return <WrappedComponent {...props} showLoading={showLoading} closeLoading={closeLoading} />;
    };
    return ComponentWithLoading;
} 