import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IAluno, IBoletim, INotas } from 'api/interfaces';
import React from 'react';

export interface IColumnsTableCell extends TableCellProps {
    id: string;
    name: string;
}

interface ITNotasDetailProps {
    aluno: IAluno | undefined;
    boletim: IBoletim | undefined;
}

interface ITitleProps {
    text: string | number;
}

const columns: IColumnsTableCell[] = [
    { id: "desc_disciplina", name: "Disciplina", width: "17%" },
    { id: "nota1", name: "1ª Nota", width: "10%" },
    { id: "nota2", name: "2ª Nota", width: "10%" },
    { id: "nota3", name: "3ª Nota", width: "10%" },
    { id: "nota4", name: "4ª Nota", width: "10%" },
    { id: "media", name: "Média", width: "10%" },
    { id: "nota_rec", name: "Nota Rec.", width: "11.5%" },
    { id: "nota_final", name: "Nota Final", width: "11.5%" },
    { id: "resultado", name: "Resultado", width: "10%" },
];


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },

        grid: {
            margin: theme.spacing(0, 0, 2),
            padding: 0.5,
            textAlign: "left",
        },

        grid_item: {
            textAlign: "left",
            color: theme.palette.primary.main,
        },

        toolbar: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },

        title: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: theme.typography.fontFamily,
        },

        text: {
            fontSize: "14px",
            fontFamily: theme.typography.fontFamily,
        },

        titleNota: {
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: theme.typography.fontFamily,
        },

        tableHead: {
            backgroundColor: "#459ad6",
            color: "#FFFFFF",
            fontFamily: theme.typography.fontFamily,
        },

        tableHeadCellTitle: {
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: 400,
            textAlign: "center",
            fontFamily: theme.typography.fontFamily,
        },

        aprovado: {
            color: "#000fff",
            fontSize: "12px",
            fontFamily: theme.typography.fontFamily,
        },
        recuperacao: {
            color: "#ff7200",
            fontSize: "12px",
            fontFamily: theme.typography.fontFamily,
        },
        reprovado: {
            color: "#ff0000",
            fontSize: "12px",
            fontFamily: theme.typography.fontFamily,
        }
    }),
);

const Title: React.FC<ITitleProps> = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {props.text}:
        </Typography>
    )
}

const Text: React.FC<ITitleProps> = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.text} color="inherit" variant="subtitle1" component="div">
            {props.text}
        </Typography>
    )
}

interface ITextNotaProps {
    nota: number;
}

const TextNota: React.FC<ITextNotaProps> = (props) => {
    const classes = useStyles();
    const { nota } = props;

    const getNotas = () => {
        if (nota < 0) {
            return (
                <Typography className={classes.reprovado} color="inherit" variant="subtitle1" component="div">
                </Typography>
            );
        } else if ((nota > 0) && (nota < 7)) {
            return (
                <Typography className={classes.reprovado} color="inherit" variant="subtitle1" component="div">
                    {nota}
                </Typography>
            );
        }
        else {
            return (
                <Typography className={classes.aprovado} color="inherit" variant="subtitle1" component="div">
                    {nota}
                </Typography>
            );

        }
    }
    return getNotas();
}

interface ITextNotaResultadoProps {
    notas: INotas;

}
const TextNotaResultado: React.FC<ITextNotaResultadoProps> = (props) => {
    const classes = useStyles();
    const { notas } = props;

    if (notas.nota_final <= 0) {
        return (
            <Typography className={classes.aprovado} color="inherit" variant="subtitle1" component="div">

            </Typography>
        );
    } else if ((notas.nota_final > 0) && (notas.nota_final < 7)) {
        return (<Typography className={classes.reprovado} color="inherit" variant="subtitle1" component="div">
            Não aprovado
        </Typography>);
    }
    else {
        return (
            <Typography className={classes.aprovado} color="inherit" variant="subtitle1" component="div">
                Aprovado
            </Typography>
        );
    }
}


const TextNotaTitle: React.FC<ITitleProps> = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.titleNota} color="inherit" variant="subtitle2" component="div">
            {props.text}
        </Typography>
    )
}

const NotasDetail: React.FC<ITNotasDetailProps> = (props) => {
    const { aluno, boletim } = props;
    const classes = useStyles();
    if (aluno && boletim) {
        return (
            <div className={classes.root}>

                {/* <Grid container className={classes.grid}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={classes.grid_item}><Title text={"Escola"} /></Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={classes.grid_item}><Text text={"Instituto Santo Inácio e Loiola"} /></Grid>

                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item}><Title text={"Aluno(a)"} /></Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item}><Title text={"Matrícula"} /></Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item} ><Text text={aluno.nome} /></Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item}><Text text={aluno.matricula_alu} /></Grid>

                    <Grid item xs={12} sm={12} md={2} xl={2} lg={2} className={classes.grid_item}><Title text={"Ano Letivo"} /></Grid>
                    <Grid item xs={12} sm={12} md={5} xl={4} lg={4} className={classes.grid_item}><Title text={"Curso"} /></Grid>
                    <Grid item xs={12} sm={12} md={5} xl={6} lg={6} className={classes.grid_item}><Title text={"Turma"} /></Grid>

                    <Grid item xs={12} sm={12} md={2} xl={2} lg={2} className={classes.grid_item}><Text text={boletim.ano_letivo} /></Grid>
                    <Grid item xs={12} sm={12} md={5} xl={4} lg={4} className={classes.grid_item}><Text text={boletim.desc_curso} /></Grid>
                    <Grid item xs={12} sm={12} md={5} xl={6} lg={6} className={classes.grid_item}><Text text={boletim.desc_turma} /></Grid>
                </Grid> */}

                <Grid container className={classes.grid}>

                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item}><Title text={"Aluno(a)"} /></Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item}><Title text={"Matrícula"} /></Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item} ><Text text={aluno.nome} /></Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} className={classes.grid_item}><Text text={aluno.matricula_alu} /></Grid>


                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Title text={"Ano Letivo"} /></Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Title text={"Curso"} /></Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Title text={"Turma"} /></Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Title text={"Escola"} /></Grid>

                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Text text={boletim.ano_letivo} /></Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Text text={boletim.desc_curso} /></Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Text text={boletim.desc_turma} /></Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3} className={classes.grid_item}><Text text={"Instituto Santo Inácio e Loiola"} /></Grid>


                </Grid>

                <Paper >
                    <TableContainer>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow className={classes.tableHead}>
                                    {
                                        columns.map((col: IColumnsTableCell, index: number) => {
                                            return (
                                                <TableCell component="th" key={col.id + index} className={classes.tableHeadCellTitle}>
                                                    {col.name}
                                                </TableCell>);
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    boletim.notas.map(
                                        (row: INotas, index: number) => {
                                            return (
                                                <TableRow hover key={index}>
                                                    {
                                                        columns.map((col: IColumnsTableCell, index: number) => {
                                                            const field: any = col.id;
                                                            const nota: any = row;
                                                            const value = nota[field] ? nota[field] : "";
                                                            if (field === 'desc_disciplina') {
                                                                return (
                                                                    <TableCell key={col.id + index} style={{ width: col.width }}>
                                                                        <TextNotaTitle text={value} />
                                                                    </TableCell>
                                                                );
                                                            } if (field === 'resultado') {
                                                                return (
                                                                    <TableCell key={col.id + index} style={{ width: col.width }}>
                                                                        <TextNotaResultado notas={nota} />
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableCell key={col.id + index} style={{ width: col.width }}>
                                                                        <TextNota nota={value} />
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </TableRow>
                                            );
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div >
        )
    } else {
        return null;
    }
}

export default NotasDetail;