import LandingPage from "pages/LandingPage/LandingPage";
import NotasPage from "pages/Notas/Notas";
import PerfilPage from "pages/Perfil/PerfilPage";
import React from "react";
import { Route, Switch } from "react-router-dom";

interface INavigationRoutersProps {

}

const NavigationRouters: React.FC<INavigationRoutersProps> = (props) => {
  return (
    <Switch>
      <Route path="/notas" component={NotasPage} />
      <Route path="/perfil" component={PerfilPage} />
      <Route path="/" component={LandingPage} />
    </Switch>
  );
}

export default NavigationRouters;
