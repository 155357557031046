import Typography from "@material-ui/core/Typography";
import SantoInacioImg from "assets/img/logo2.png";
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyle = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        margin: theme.spacing(1, 0, 1),
        width: "auto",
    },
    title: {
        width: "100%", // Fix IE 11 issue.
        margin: theme.spacing(2, 1, 2),
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        textAlign: "center"
    }
}));


interface ILogoMarcaProps {
    title?: string;
    logomarca?: string;
}

const LogoMarca: React.FC<ILogoMarcaProps> = (props) => {
    const { title, logomarca } = props;
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <img src={logomarca} alt={title} className={classes.logo} />
            <Typography component="h1" variant="h5" className={classes.title}>
                {title}
            </Typography>
        </div>
    );
}

LogoMarca.defaultProps = {
    title: "Portal do Aluno",
    logomarca: SantoInacioImg
}

export default LogoMarca;