import { ThemeProvider, useTheme } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Navigation from "layout/Navigation";
import ChangePassword from "pages/Auth/ChangePassword/ChangePassword";
import PasswordReset from "pages/Auth/PasswordReset/PasswordReset";
import SignUp from "pages/Auth/SignUp/SignUp";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import Login from "./pages/Auth/Login/Login";
import { selectLoggedIn } from "./reducers/loginReducer";

function PrivateRoute({ children, ...rest }: RouteProps) {

  const logged_in = useSelector(selectLoggedIn);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        logged_in ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
      }
    />
  );
}

export default function App() {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/reset-password" component={PasswordReset} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/sign-up" component={SignUp} />
        <PrivateRoute path="*">
          <Navigation />
        </PrivateRoute>
      </Switch>
    </ThemeProvider>
  );
}
