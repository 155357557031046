import { IUser } from "api/interfaces";


export default class UsuarioModel {
    usuarioId: number = 0;
    perfilAcessoId: number = 0;;
    username: string = "";
    nome: string = "";
    fone: string = "";
    celular: string = "";
    email: string = "";
    accessToken: string = "";
    status: number = 0;
    sexoId: string = "";
    dataNascimento: string = "";
    password: string = "";
    repeatPassword: string = "";

    public constructor(init?: Partial<IUser>) {
        Object.assign(this, init);
    }
}