import { Box, FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import HttpClient, { API_USUARIO_CHANGE_PASSWORD } from "api/Api";
import { IResponseApi } from "api/interfaces";
import { InputUsername } from "components";
import InputPassword from "components/InputPassword";
import WithAlert, { IWithAlertProps } from "hoc/WithAlert";
import WithLoading, { IWithLoadingProps } from "hoc/WithLoading";
import WithUserLogin, { IWithUserLoginProps } from "hoc/WithUserLogin";
import React from "react";
import updatePasswordStyle from "./updatePasswordStyle";

interface IUpdatePasswordProps {

}

interface IFormState {
  username: string;
  password: string;
  confirmPassword: string;
}

const UpdatePassword: React.FC<IUpdatePasswordProps> = (props) => {

  const classes = updatePasswordStyle();
  const { showLoading, closeLoading } = props as IWithLoadingProps;
  const { alertError, alertInfo } = props as IWithAlertProps;
  const { getUser } = props as IWithUserLoginProps;

  const [values, setValues] = React.useState<IFormState>({ username: getUser().username, password: '', confirmPassword: '' });
  const [messageError, setMessageError] = React.useState<string>("");

  const handleChange = (prop: keyof IFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setMessageError('');
  };

  const handleErrorNotification = (mensagem: string) => {
    closeLoading && closeLoading();
    alertError && alertError(mensagem);
  }

  const handleInfoNotification = (mensagem: string) => {
    alertInfo && alertInfo(mensagem);
  }

  const handleValidate = (): boolean => {
    let result = false;
    if (values.password.length <= 0) {
      setMessageError('Senha não informada.');
    } else if (values.confirmPassword.length <= 0) {
      setMessageError('Confirmação da senha não informada.');
    } else if ((values.password.length > 0) && (values.confirmPassword.length > 0) && (values.password !== values.confirmPassword)) {
      setMessageError('As senhas são diferentes.');
    } else {
      setMessageError('');
      result = true;
    }
    return result;
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (handleValidate()) {
      showLoading && showLoading();
      const bodyFormData = new FormData();
      bodyFormData.append('password', values.password);
      bodyFormData.append('password_repeat', values.confirmPassword);
      bodyFormData.append('username', values.username);
      HttpClient.post(API_USUARIO_CHANGE_PASSWORD, bodyFormData).then((response) => {

        const resp = (response.data as IResponseApi);
        if (resp.success) {
          handleInfoNotification(resp.message);
        } else {
          handleErrorNotification(resp.message);
        }
      })
        .catch((err: Error) => {
          handleErrorNotification(err.message);
        });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputUsername
            label="Usuário"
            value={values.username}
            disabled={true}
          />
        </Box>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputPassword
            label="Senha"
            value={values.password}
            onChange={handleChange('password')}
            required
            validate
          />
        </Box>

        <Box mt={1} mb={1} style={{ width: "100%" }}>
          <InputPassword
            label="Confirmação da senha"
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            required
            validate
            matchValue={values.password}
          />
        </Box>
        {
          (messageError.length > 0) && (<FormHelperText error={messageError.length > 0}>{messageError}</FormHelperText>)
        }

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </div>
    </Container>
  );
}

export default WithAlert(
  WithUserLogin(
    WithLoading(UpdatePassword)
  )
);