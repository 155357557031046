import { Collapse, createStyles, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SchoolIcon from '@material-ui/icons/School';
import { IAluno, IBoletim } from 'api/interfaces';
import React, { useEffect } from 'react';

interface IListAlunoProps {
    alunos: IAluno[];
    onSelectAluno: (aluno: IAluno) => void;
    onSelectBoletim: (boletim: IBoletim) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        aluno: {
            fontWeight: "bold",
            fontSize: "11px",
            fontFamily: theme.typography.fontFamily,
        },

        list_icon: {
            minWidth: "30px",
        },

        boletim: {
            fontSize: "11px",
            fontFamily: theme.typography.fontFamily,
        },

        nested: {
            fontSize: "11px",
            paddingLeft: theme.spacing(3),
            fontFamily: theme.typography.fontFamily,
        },
    }),
);

const ListAluno: React.FC<IListAlunoProps> = (props) => {

    const classes = useStyles();
    const { alunos, onSelectAluno, onSelectBoletim } = props;
    const [selectedIndex, setSelectedIndex] = React.useState("");
    const [selectedAlunoId, setSelectedAlunoId] = React.useState<any>({});

    useEffect(() => {
        let effect = true;
        if (effect) {
            const opens: any = {};
            for (const aluno of alunos) {
                opens[aluno.aluno_id] = false;
            }
            setSelectedAlunoId(opens);
        }
        return () => {
            effect = false
        }
    }, [alunos])

    const handleOpenCollapse = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, aluno_id: number) => {
        const values: any = Object.assign({}, selectedAlunoId);
        values[aluno_id] = !values[aluno_id];
        setSelectedAlunoId(values);
    };

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, aluno: IAluno, boletim: IBoletim) => {
        const idx = getKey(boletim, aluno.matricula_alu);
        setSelectedIndex(idx);
        onSelectAluno && onSelectAluno(aluno);
        onSelectBoletim && onSelectBoletim(boletim);
    };

    const getKey = (boletim: IBoletim, matricula_alu: number) => {
        return matricula_alu + "_" + boletim.ano_letivo + "_" + boletim.curso_id + "_" + boletim.modulo_curso_id + "_" + boletim.turma_id;
    }

    return (
        <List component="nav" aria-label="main mailbox folders">
            {
                alunos.map((aluno: IAluno, index: number) => {
                    return (
                        <div key={aluno.aluno_id}>
                            <ListItem
                                button
                                onClick={(e) => handleOpenCollapse(e, aluno.aluno_id)}
                                className={classes.aluno}
                            >
                                <ListItemIcon className={classes.list_icon}>
                                    <SchoolIcon />
                                </ListItemIcon>
                                <ListItemText primary={aluno.nome} className={classes.boletim} />
                                {(selectedAlunoId[aluno.aluno_id]) ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Divider />
                            <Collapse in={selectedAlunoId[aluno.aluno_id]} timeout="auto" unmountOnExit>
                                {
                                    aluno.boletim.map((boletim: IBoletim, index2: number) => {
                                        const idx = getKey(boletim, aluno.matricula_alu);
                                        return (<List component="div" disablePadding>
                                            <ListItem
                                                button
                                                className={classes.nested}
                                                selected={selectedIndex === idx}
                                                onClick={(e) => handleListItemClick(e, aluno, boletim)}
                                            >
                                                <ListItemIcon className={classes.list_icon}>
                                                    <MenuBookIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    className={classes.boletim}
                                                    primary={boletim.ano_letivo + " - " + boletim.desc_turma}
                                                />
                                            </ListItem>
                                        </List>
                                        );
                                    })
                                }
                            </Collapse>
                        </div>
                    );
                })
            }
        </List>
    )
}

export default ListAluno;