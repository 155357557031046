export const StorageHelper = {

    clear: () => {
        window.localStorage.clear();
    },

    getItem: (key: string) => {
        return window.localStorage.getItem(key);
    },

    key: (index: number) => {
        return window.localStorage.key(index);
    },

    has: (key: string) => {
        return (localStorage.getItem(key) === null);
    },

    removeItem: (key: string) => {
        window.localStorage.removeItem(key);
    },

    setItem: (key: string, value: string) => {
        window.localStorage.setItem(key, value);
    },
}