import {
    Action,
    combineReducers,
    configureStore,
    getDefaultMiddleware,
    ThunkAction
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import alertReducer from "./alertReducer";
import loginReducer from "./loginReducer";
import pageLoadReducer from "./pageLoadReducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["login"], // only loginReducer will be persisted
};

const rootReducer = combineReducers({
    login: loginReducer,
    pageLoad: pageLoadReducer,
    alert: alertReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
